import React from "react"
import Swiper from "react-id-swiper"
import Img from "gatsby-image"
import "swiper/css/swiper.css"

const SwipeImage = screenData => {
  const params = {
    slidesPerView: "auto",
    spaceBetween: 30,
    centeredSlides: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      dynamicBullets: true,
    },
  }
  const screenMobile = screenData.data.map(item => (
    <div key={item.node.childImageSharp.id} className="project-mobile">
      <Img fluid={item.node.childImageSharp.fluid} />
    </div>
  ))

  return <Swiper {...params}>{screenMobile}</Swiper>
}

export default SwipeImage

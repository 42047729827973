import React from "react"
import Image from "./image"

export default function Skills() {
  const skills = [
    "HTML5, CSS3, Bootstrap, Materialize",
    "JavaScript, React, Gatsby",
    "PHP, Codeigniter, Mysql",
  ]
  const listItem = skills.map((skill, index) => (
    <li key={index}>
      <div className="icon-list">
        <Image name="iconList" />
      </div>
      <span className="pl-2">{skill}</span>
    </li>
  ))

  return <ul className="list-item-icon">{listItem}</ul>
}

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import Header from "./header"
import Footer from "./footer"
import Image from "./image"
import "bootstrap/dist/css/bootstrap.min.css"
import "../styles/custom.css"

const Layout = ({ children }) => {
  const threshold = 0.25
  const scrollFunction = () => {
    // Validacion del scroll con respecto a la pantalla
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      document.getElementById("scroll-navbar").style.background = "white"
    } else {
      document.getElementById("scroll-navbar").style.background = "transparent"
    }
    //-------------------------------------------------------------------------

    //Creamos un observable para el boton de whatsapp
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: threshold,
    })

    const navbar = document.querySelector("footer")
    observer.observe(navbar)

    function handleIntersection(entries) {
      const entry = entries[0]
      const wp = document.getElementsByClassName("icon-whatsapp")[0]
      const heightFooter = document.querySelector("footer").offsetHeight + 10
      entry.isIntersecting
        ? (wp.style.bottom = `${heightFooter}px`)
        : (wp.style.bottom = `15px`)
    }
    //----------------------------------------------------------------------------
  }

  useEffect(() => {
    window.onscroll = () => {
      scrollFunction()
    }
  }, [])

  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer />
      <div className="icon-whatsapp">
        <a
          href="https://api.whatsapp.com/send?phone=542657357474&text=Hola,%20estoy%20interesado%20en%20tus%20servicios"
          target="_blank"
          rel="noopener noreferrer"
          className="pulse"
        >
          <Image name="whatsapp" />
        </a>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import React, { useState, useEffect } from "react"
import Image from "./image"
import { Col, Row, Modal } from "react-bootstrap"
// import { useStaticQuery, graphql } from "gatsby"
import dataProject from "../data/data.json"

export default function Projects() {
  const [show, setShow] = useState(false)
  const [projectWeb, setProjectWeb] = useState({})
  const [media, setMedia] = useState(false)

  useEffect(() => {
    setMedia(window.matchMedia("(max-width: 576px)").matches)
  }, [])

  const handleClose = () => setShow(false)

  const handleShow = (url, image, title) => {
    debugger
    setProjectWeb({ url, image, title })
    setShow(true)
  }

  // const dataProject = useStaticQuery(graphql`
  //   query DataJson {
  //     allDataJson {
  //       edges {
  //         node {
  //           id
  //           image
  //           name
  //           link
  //         }
  //       }
  //     }
  //   }
  // `)
  // const ListProjects = dataProject.allDataJson.edges.map((project, index) => (
  //   <Col lg="3" md="3" sm="3" xs="6" key={index + 1}>
  //     <div
  //       data-sal="slide-up"
  //       data-sal-delay="300"
  //       data-sal-easing="ease"
  //       data-sal-duration="800"
  //     >
  //       <div className="project-picture">
  //         <div className="project-caption d-flex justify-content-center align-items-center">
  //           <div className="project-description">
  //             <h3 className="d-none d-sm-block">{project.node.name}</h3>
  //             <div className="line d-none d-sm-block"></div>
  //             <a
  //               href={project.node.link}
  //               target="_blank"
  //               rel="noopener noreferrer"
  //               className="btn-outline"
  //             >
  //               Ir al sitio
  //             </a>
  //           </div>
  //         </div>
  //         <Image name={project.node.image} />
  //       </div>
  //     </div>
  //   </Col>
  // ))

  // const ListProjectsMobile = dataProject.allDataJson.edges.map(
  //   (project, index) => (
  //     <Col className="galleryMobile" xs="4" key={index + 1}>
  //       <div
  //         data-sal="slide-up"
  //         data-sal-delay="300"
  //         data-sal-easing="ease"
  //         data-sal-duration="800"
  //       >
  //         <div
  //           className="project-picture"
  //           onClick={() =>
  //             handleShow(
  //               project.node.link,
  //               project.node.image,
  //               project.node.name
  //             )
  //           }
  //           aria-hidden="true"
  //         >
  //           <Image name={project.node.image} />
  //         </div>
  //       </div>
  //     </Col>
  //   )
  // )

  const ListProjects = dataProject.map((project, index) => (
    <Col lg="3" md="3" sm="3" xs="6" key={index + 1}>
      <div
        data-sal="slide-up"
        data-sal-delay="300"
        data-sal-easing="ease"
        data-sal-duration="800"
      >
        <div className="project-picture">
          <div className="project-caption d-flex justify-content-center align-items-center">
            <div className="project-description">
              <h3 className="d-none d-sm-block">{project.name}</h3>
              <div className="line d-none d-sm-block"></div>
              <a
                href={project.link}
                target="_blank"
                rel="noopener noreferrer"
                className="btn-outline"
              >
                Ir al sitio
              </a>
            </div>
          </div>
          <Image name={project.image} />
        </div>
      </div>
    </Col>
  ))

  const ListProjectsMobile = dataProject.map((project, index) => (
    <Col className="galleryMobile" xs="4" key={index + 1}>
      <div
        data-sal="slide-up"
        data-sal-delay="300"
        data-sal-easing="ease"
        data-sal-duration="800"
      >
        <div
          className="project-picture"
          onClick={() => handleShow(project.link, project.image, project.name)}
          aria-hidden="true"
        >
          <Image name={project.image} />
        </div>
      </div>
    </Col>
  ))

  return (
    <Row>
      {media ? ListProjectsMobile : ListProjects}
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className="title-modal">
              {projectWeb.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Image name={projectWeb.image} />
          </Modal.Body>
          <Modal.Footer>
            <a
              href={projectWeb.url}
              target="_blank"
              rel="noopener noreferrer"
              className="btn-outline"
            >
              Ir al sitio
            </a>
          </Modal.Footer>
        </Modal>
      </>
    </Row>
  )
}

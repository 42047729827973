import React from "react"

const Footer = () => (
  <footer>
    <div className="container text-center">
      ¡Hecho con <div className="heart-shape"></div> para el mundo!
    </div>
  </footer>
)

export default Footer

import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Row, Col } from "react-bootstrap"
import SwipeImages from "./swipeImage"

const ListImages = ({ name }) => {
  const [media, setMedia] = useState(false)

  useEffect(() => {
    setMedia(window.matchMedia("(max-width: 576px)").matches)
  }, [])

  const data = useStaticQuery(graphql`
    query {
      sirelsoftData: allFile(
        filter: {
          relativeDirectory: { eq: "projects/desing-mobile/sirelsoft" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              id
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const listImages = (
    <Row>
      {data[name].edges.map(({ node }) => (
        <Col
          lg="3"
          md="3"
          xs="6"
          className="mb-4 project-mobile"
          key={node.childImageSharp.id}
        >
          <div
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease"
            data-sal-duration="800"
          >
            <Img fluid={node.childImageSharp.fluid} />
          </div>
        </Col>
      ))}
    </Row>
  )

  const imagesSwipe = <SwipeImages data={data[name].edges} />

  return <>{media ? imagesSwipe : listImages}</>
}

export default ListImages

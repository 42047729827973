import React from "react"
import Image from "./image"

export default function Information({ icon, title, description }) {
  return (
    <div className="d-flex align-items-center mb-3">
      <div className="icon-information">
        <Image name={icon} />
      </div>
      <div className="text-information">
        <span>{title}</span>
        <p>{description}</p>
      </div>
    </div>
  )
}

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = ({ name }) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo-jfelizzolat.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      jeffreyFelizzola: file(relativePath: { eq: "jeffrey-felizzola.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      jeffreyFelizzolaMobile: file(
        relativePath: { eq: "jeffrey-felizzola-mobile.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      country: file(relativePath: { eq: "nationality.png" }) {
        childImageSharp {
          fluid(maxWidth: 40) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      location: file(relativePath: { eq: "location.png" }) {
        childImageSharp {
          fluid(maxWidth: 40) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      age: file(relativePath: { eq: "age.png" }) {
        childImageSharp {
          fluid(maxWidth: 40) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      telephone: file(relativePath: { eq: "telephone.png" }) {
        childImageSharp {
          fluid(maxWidth: 40) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      email: file(relativePath: { eq: "email.png" }) {
        childImageSharp {
          fluid(maxWidth: 40) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      iconList: file(relativePath: { eq: "icon-list.png" }) {
        childImageSharp {
          fluid(maxWidth: 40) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      pictureSkills: file(relativePath: { eq: "picture-skills.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      pictureContact: file(relativePath: { eq: "picture-contact.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      whatsapp: file(relativePath: { eq: "whatsapp.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      sirelsoft: file(relativePath: { eq: "projects/sirelsoft.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      fremavi: file(relativePath: { eq: "projects/fremavi.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      hseqsoligen: file(relativePath: { eq: "projects/hseqsoligen.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      gerso: file(relativePath: { eq: "projects/gerso.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      mts: file(relativePath: { eq: "projects/mts.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      medilaboral: file(relativePath: { eq: "projects/medilaboral.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      mediserrano: file(relativePath: { eq: "projects/mediserrano.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      porsalud: file(relativePath: { eq: "projects/porsalud.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      somedin: file(relativePath: { eq: "projects/somedin.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      sirelsoftLogin: file(relativePath: { eq: "projects/login.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return data[name].childImageSharp.fluid === undefined ? (
    <Img fixed={data[name].childImageSharp.fixed} />
  ) : (
    <Img fluid={data[name].childImageSharp.fluid} />
  )
}

export default Image

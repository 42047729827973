import React, { useState } from "react"
import Image from "../components/image"
import ListImages from "../components/listImages"
import SEO from "../components/seo"
import {
  Row,
  Col,
  Container,
  Form,
  Button,
  Spinner,
  Alert,
} from "react-bootstrap"
import Information from "../components/information"
import Skills from "../components/skills"
import Projects from "../components/projects"
import Layout from "../components/layout"
import scrollTo from "gatsby-plugin-smoothscroll"
import downloadFile from "../data/CV_Jeffrey_Felizzola_Tapia_2020.pdf"
import coverImage from "../images/cover-image.jpg"

const currentDate = new Date()
const currentExperience = currentDate.getFullYear() - 2015

const calcularEdad = FechaNacimiento => {
  const fechaNace = new Date(FechaNacimiento)
  const fechaActual = new Date()

  const mes = fechaActual.getMonth()
  const dia = fechaActual.getDate()
  const año = fechaActual.getFullYear()

  fechaActual.setDate(dia)
  fechaActual.setMonth(mes)
  fechaActual.setFullYear(año)

  const edad = Math.floor(
    (fechaActual - fechaNace) / (1000 * 60 * 60 * 24) / 365
  )

  const edadActual = edad + " Años"

  return edadActual
}

function IndexPage() {
  const [validated, setValidated] = useState(false)
  const [sending, setSending] = useState({
    spinner: "d-none",
    enviando: false,
    text_sending: "Enviar",
  })
  const [result, setResult] = useState({
    show: false,
    variant: "",
    message: "",
  })

  const handleSubmit = async event => {
    const form = event.currentTarget
    setValidated(true)
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      event.preventDefault()
      setSending({
        spinner: "",
        enviando: true,
        text_sending: "Enviando...",
      })
      const data = {
        name: form.name.value,
        email: form.email.value,
        message: form.message.value,
      }
      const response = await fetch("/api/sendMail", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
      if (response.ok) {
        setResult({
          show: true,
          variant: "success",
          message: "¡Mensaje enviado satisfactoriamente! :)",
        })
        form.reset()
      } else {
        setResult({
          show: true,
          variant: "danger",
          message: "El mensaje no pudo ser enviado :(",
        })
      }
      setValidated(false)
      setSending({
        spinner: "d-none",
        enviando: false,
        text_sending: "Enviar",
      })
      setTimeout(() => {
        setResult({
          show: false,
          variant: "",
          message: "",
        })
      }, 3000)
    }
  }
  const urlCoverImg = `https://jfelizzolat.dev${coverImage}`
  return (
    <Layout>
      <SEO title="jfelizzolat" image={urlCoverImg} />
      <header id="home" className="header position-relative">
        <Container>
          <div className="rectangle-container d-none d-sm-block">
            <div className="rectangle-outline rectangle-animation-1"></div>
            <div className="rectangle-background rectangle-animation-2"></div>
            <div className="rectangle-outline-in"></div>
          </div>
          <Row className="d-block d-sm-none">
            <div className="rectangle-mobile">
              <div className="rectangle-1"></div>
              <div className="rectangle-2"></div>
              <div className="rectangle-3"></div>
              <div className="rectangle-4"></div>

              <div
                data-sal="slide-down"
                data-sal-delay="200"
                data-sal-easing="ease"
                data-sal-duration="800"
              >
                <div className="photoMobile">
                  <Image name="jeffreyFelizzolaMobile" />
                </div>
              </div>
              <div
                data-sal="slide-down"
                data-sal-delay="100"
                data-sal-easing="ease"
                data-sal-duration="800"
              >
                <h2>Jeffrey Felizzola Tapia</h2>
                <h3>Desarrollador Frontend</h3>
              </div>
            </div>
          </Row>

          <Row>
            <Col lg="6" md="6" sm="6" xs="12" className="padding-description">
              <h2
                className="text-blue-dark d-none d-sm-block"
                data-sal="slide-up"
                data-sal-delay="300"
                data-sal-easing="ease"
                data-sal-duration="800"
              >
                ¡Hola!
              </h2>
              <h1
                className="text-blue-dark d-none d-sm-block"
                data-sal="slide-up"
                data-sal-delay="400"
                data-sal-easing="ease"
                data-sal-duration="800"
              >
                Soy <span>desarrollador Frontend</span>
              </h1>
              <p
                className="text-justify"
                data-sal="slide-up"
                data-sal-delay="500"
                data-sal-easing="ease"
                data-sal-duration="800"
              >
                Cuento con más de {currentExperience} años de experiencia en el
                área. Mi experiencia se basa en el desarrollo de aplicaciones
                web, páginas web y diseño de interfaces de usuarios (UI).
              </p>
              <div
                className="float-right group-btn-description"
                data-sal="slide-up"
                data-sal-delay="600"
                data-sal-easing="ease"
                data-sal-duration="800"
              >
                <button
                  className="btn-outline"
                  onClick={() => scrollTo("#contact")}
                >
                  Hablemos
                </button>
                <a download href={downloadFile} className="btn-outline ml-2">
                  Descarga mi CV
                </a>
              </div>
            </Col>
            <Col lg="6" className="d-none d-sm-block">
              <div
                data-sal="slide-left"
                data-sal-delay="100"
                data-sal-easing="ease"
                data-sal-duration="800"
              >
                <div className="profile-picture">
                  <Image name="jeffreyFelizzola" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </header>
      <section id="about-me" className="bg-gray-light pt-4">
        <div className="restangle-small">
          <div className="rectangle-outline-small"></div>
          <div className="rectangle-background-small"></div>
        </div>
        <Container className="pt-5 pb-5">
          <Row>
            <Col>
              <h2
                className="text-blue-dark"
                data-sal="slide-up"
                data-sal-delay="300"
                data-sal-easing="ease"
              >
                Sobre <span>mí</span>
              </h2>
            </Col>
          </Row>
          <Row>
            <Col lg="7" md="7">
              <p
                className="text-justify"
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-easing="ease"
                data-sal-duration="800"
              >
                Soy Jeffrey Felizzola Tapia, ingeniero de sistemas graduado de
                la Universidad de investigación y desarrollo (UDI) en
                Bucaramanga, Santander (Colombia). Poseo conocimientos en diseño
                de interfaces de usuario (UI), experiencia de usuarios (UX) y
                desarrollo web.
              </p>
              <p
                className="text-justify"
                data-sal="slide-up"
                data-sal-delay="300"
                data-sal-easing="ease"
                data-sal-duration="800"
              >
                Cuento con más de {currentExperience} años de experiencia en
                desarrollo de software y diseño web, como independiente y
                laborando para empresas dedicadas al desarrollo de software.
              </p>
              <p
                className="text-justify"
                data-sal="slide-up"
                data-sal-delay="400"
                data-sal-easing="ease"
                data-sal-duration="800"
              >
                Además como independiente he podido realizar diseños de
                interfaces para aplicaciones móviles y estar en constante
                aprendizaje de nuevas tecnologías.
              </p>
            </Col>
            <Col lg="5" md="5">
              <div
                data-sal="slide-left"
                data-sal-delay="100"
                data-sal-easing="ease"
                data-sal-duration="800"
              >
                <Information
                  icon="country"
                  title="Nacionalidad"
                  description="Colombiano"
                />
              </div>

              <div
                data-sal="slide-left"
                data-sal-delay="200"
                data-sal-easing="ease"
                data-sal-duration="800"
              >
                <Information
                  icon="location"
                  title="Ubicación"
                  description="Villa mercedes - San Luis, Argentina"
                />
              </div>

              <div
                data-sal="slide-left"
                data-sal-delay="300"
                data-sal-easing="ease"
                data-sal-duration="800"
              >
                <Information
                  icon="age"
                  title="Edad"
                  description={calcularEdad("01/11/1993")}
                />
              </div>

              <div
                data-sal="slide-left"
                data-sal-delay="400"
                data-sal-easing="ease"
                data-sal-duration="800"
              >
                <Information
                  icon="telephone"
                  title="Teléfono"
                  description="+54 2657 357474"
                />
              </div>

              <div
                data-sal="slide-left"
                data-sal-delay="500"
                data-sal-easing="ease"
                data-sal-duration="800"
              >
                <Information
                  icon="email"
                  title="E-mail"
                  description="developer@jfelizzolat.dev"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="skills" className="pt-4">
        <Container className="pt-5 pb-5">
          <Row>
            <Col lg="5" md="5">
              <h2
                className="text-blue-dark"
                data-sal="slide-up"
                data-sal-delay="300"
                data-sal-easing="ease"
              >
                Mis <span>habilidades</span>
              </h2>
              <p
                className="text-justify"
                data-sal="slide-up"
                data-sal-delay="400"
                data-sal-easing="ease"
              >
                En transcurso del tiempo que llevo desarrollando he adquirido
                conocimientos en tecnologías como:
              </p>
              <div
                data-sal="slide-up"
                data-sal-delay="500"
                data-sal-easing="ease"
              >
                <Skills />
              </div>
            </Col>
            <Col lg="7" md="7">
              <div
                data-sal="slide-left"
                data-sal-delay="100"
                data-sal-easing="ease"
                data-sal-duration="800"
              >
                <Image name="pictureSkills" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="projects" className="bg-gray-light pt-4">
        <div className="restangle-small">
          <div className="rectangle-outline-small"></div>
          <div className="rectangle-background-small"></div>
        </div>
        <Container className="pt-5 pb-5">
          <Row>
            <Col lg="12" md="12">
              <h2 className="text-blue-dark">Proyectos</h2>
              <h4 className="sub-title">
                <div className="icon-list">
                  <Image name="iconList" />
                </div>
                <span>Diseño y desarrollo de páginas web</span>
              </h4>
            </Col>
          </Row>
          <Projects />
        </Container>
      </section>
      <section>
        <Container className="pt-5 pb-5">
          <Row>
            <Col lg="12" md="12">
              <h4 className="sub-title">
                <div className="icon-list">
                  <Image name="iconList" />
                </div>
                <span>Diseño de aplicación móvil</span>
              </h4>
            </Col>
          </Row>
          <Row>
            <Col lg="3" md="3">
              <div
                data-sal="slide-right"
                data-sal-delay="100"
                data-sal-easing="ease"
                data-sal-duration="800"
              >
                <p className="text-justify text-black-50">
                  <b>Sirelsoft</b> es un aplicación web que permite controlar
                  los sitemas de gestión y riesgos laborales. Su aplicación
                  móvil ya se encuentra en desarrollo luego de haber sido
                  aprobado el diseño de interfaces.
                </p>
              </div>
              <div
                className="mb-4"
                data-sal="slide-right"
                data-sal-delay="200"
                data-sal-easing="ease"
                data-sal-duration="800"
              >
                <Row>
                  <Col
                    xs={{ span: 8, offset: 2 }}
                    sm={{ span: 8, offset: 2 }}
                    md={{ span: 12, offset: 0 }}
                    lg={{ span: 12, offset: 0 }}
                  >
                    <Image name="sirelsoftLogin" />
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg="9" md="9" xs="12">
              <ListImages name="sirelsoftData" />
            </Col>
          </Row>
        </Container>
      </section>
      <section id="contact" className="bg-gray-light pt-4">
        <Container className="pt-5 pb-5">
          <Row>
            <Col lg="7" md="7">
              <div
                className="mb-3"
                data-sal="slide-left"
                data-sal-delay="100"
                data-sal-easing="ease"
                data-sal-duration="800"
              >
                <Image name="pictureContact" />
              </div>
            </Col>
            <Col lg="5" md="5">
              <h2 className="text-blue-dark">Hablemos</h2>
              <p className="text-justify">
                Déjame tus datos y cuéntame un poco en que te puedo ayudar.
              </p>
              <Alert show={result.show} variant={result.variant}>
                {result.message}
              </Alert>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div>
                  <Form.Group controlId="formBasicName">
                    <Form.Label>Nombre completo</Form.Label>
                    <Form.Control required type="text" name="name" />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Correo electrónico</Form.Label>
                    <Form.Control required type="email" name="email" />
                  </Form.Group>
                  <Form.Group controlId="formBasicMenssage">
                    <Form.Label>Mensaje</Form.Label>
                    <Form.Control
                      required
                      as="textarea"
                      rows="3"
                      name="message"
                    />
                  </Form.Group>
                  <Button type="submit" className="btn-outline">
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className={sending.spinner}
                    />
                    <span className={sending.enviando ? "ml-2" : null}>
                      {sending.text_sending}
                    </span>
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default IndexPage

import PropTypes from "prop-types"
import React from "react"
import { Navbar, Nav, Container } from "react-bootstrap"
import Image from "./image"
import scrollTo from "gatsby-plugin-smoothscroll"

const Header = () => (
  <Navbar id="scroll-navbar" collapseOnSelect expand="lg" fixed="top">
    <Container>
      <Navbar.Brand href="/">
        <div style={{ width: `177px` }}>
          <Image name="logo" />
        </div>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse
        id="responsive-navbar-nav"
        className="justify-content-end"
      >
        <Nav>
          <Nav.Link
            href="#"
            onClick={() => scrollTo("#home")}
            className="text-link"
          >
            Inicio
          </Nav.Link>
          <Nav.Link
            href="#"
            onClick={() => scrollTo("#about-me")}
            className="text-link"
          >
            Sobre mí
          </Nav.Link>
          <Nav.Link
            href="#"
            onClick={() => scrollTo("#skills")}
            className="text-link"
          >
            Habilidades
          </Nav.Link>
          <Nav.Link
            href="#"
            onClick={() => scrollTo("#projects")}
            className="text-link"
          >
            Proyectos
          </Nav.Link>
          <Nav.Link
            href="#"
            onClick={() => scrollTo("#contact")}
            className="btn-outline ml-2"
          >
            Hablemos
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
